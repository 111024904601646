/* Application-wide Styles */
@import './assets/w3.css';
@import './assets/raleway.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';



.w3-bar .w3-button {
  padding: 16px;
}

.perspective-blue {
  background: #4D84C5;
}

